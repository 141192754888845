import React from 'react'
import Layout from '../layout/layout'
import { Link } from 'gatsby'

const MusicPage = () => (
    <Layout>
        <header className="post-header">
            <h1 className="post-title">Music - Aerostatic Project</h1>
        </header>
        <article className="post-content">
            <p>
                Comming soon... I just love playing music...
            </p>
            <p>
                Follow me on <a href="https://instagram.com/aerostatic.music/" target="_blank" rel="noreferrer">@aerostatic.music</a>
            </p>
            <p>
                <Link to="/about">More about me</Link>
            </p>
        </article>
    </Layout>
)

export default MusicPage